export const ORDER_SORT = [
  { value: 'latest', label: '최신순' },
  { value: 'review', label: '리뷰순' },
];

export const JOB_SORT = [
  { value: '', label: '전체 직군' },
  { value: '2', label: '서버 개발자' },
  { value: '3', label: '프론트엔드 개발자' },
];

export const SKILL_SORT_BE = [
  { value: '', label: '전체 기술' },
  { value: 'AWS', label: 'AWS' },
  { value: 'Python', label: 'Python' },
  { value: 'MySQL', label: 'MySQL' },
  { value: 'Java', label: 'Java' },
  { value: 'Spring Framework', label: 'Spring Framework' },
  { value: 'Github', label: 'Github' },
  { value: 'Docker', label: 'Docker' },
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Node.js', label: 'Node.js' },
  { value: 'SQL', label: 'SQL' },
  { value: 'Linux', label: 'Linux' },
  { value: 'Spring Boot', label: 'Spring Boot' },
  { value: 'JIRA', label: 'JIRA' },
  { value: 'JPA', label: 'JPA' },
  { value: 'Kubernetes', label: 'Kubernetes' },
  { value: 'Redis', label: 'Redis' },
  { value: 'C++', label: 'C++' },
  { value: 'PostgreSQL', label: 'PostgreSQL' },
  { value: 'Restful API', label: 'Restful API' },
  { value: 'MongoDB', label: 'MongoDB' },
  { value: 'Django', label: 'Django' },
  { value: 'Jenkins', label: 'Jenkins' },
  { value: 'Go', label: 'Go' },
  { value: 'ElasticSearch', label: 'ElasticSearch' },
  { value: 'PHP', label: 'PHP' },
  { value: 'NoSQL', label: 'NoSQL' },
  { value: 'C', label: 'C' },
  { value: 'RDBMS', label: 'RDBMS' },
  { value: 'Oracle', label: 'Oracle' },
  { value: 'API', label: 'API' },
  { value: 'DevOps', label: 'DevOps' },
  { value: 'Golang', label: 'Golang' },
  { value: 'Nginx', label: 'Nginx' },
  { value: 'MSSQL', label: 'MSSQL' },
  { value: 'GraphQL', label: 'GraphQL' },
  { value: 'RabbitMQ', label: 'RabbitMQ' },
  { value: 'ORM', label: 'ORM' },
  { value: 'TDD', label: 'TDD' },
  { value: 'R', label: 'R' },
  { value: 'Hadoop', label: 'Hadoop' },
  { value: 'Laravel', label: 'Laravel' },
];

export const SKILL_SORT_FE = [
  { value: '', label: '전체 기술' },
  { value: 'Angular', label: 'Angular' },
  { value: 'CSS', label: 'CSS' },
  { value: 'Docker', label: 'Docker' },
  { value: 'Figma', label: 'Figma' },
  { value: 'HTML', label: 'HTML' },
  { value: 'JavaScript', label: 'JavaScript' },
  { value: 'Laravel', label: 'Laravel' },
  { value: 'Next.js', label: 'Next.js' },
  { value: 'Node.js', label: 'Node.js' },
  { value: 'React', label: 'React' },
  { value: 'React.js', label: 'React.js' },
  { value: 'Svelte', label: 'Svelte' },
  { value: 'TypeScript', label: 'TypeScript' },
  { value: 'UX', label: 'UX' },
  { value: 'Vue.JS', label: 'Vue.JS' },
  { value: 'VueJS', label: 'VueJS' },
  { value: 'WebGL', label: 'WebGL' },
  { value: 'WebRTC', label: 'WebRTC' },
];

export const SKILL_SORT_ALL = [
  { value: '', label: '전체 기술' },
  { value: 'AWS', label: 'AWS' },
  { value: 'Python', label: 'Python' },
  { value: 'MySQL', label: 'MySQL' },
  { value: 'Java', label: 'Java' },
  { value: 'Spring Framework', label: 'Spring Framework' },
  { value: 'Github', label: 'Github' },
  { value: 'Docker', label: 'Docker' },
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Node.js', label: 'Node.js' },
  { value: 'SQL', label: 'SQL' },
  { value: 'Linux', label: 'Linux' },
  { value: 'Spring Boot', label: 'Spring Boot' },
  { value: 'JIRA', label: 'JIRA' },
  { value: 'JPA', label: 'JPA' },
  { value: 'Kubernetes', label: 'Kubernetes' },
  { value: 'Redis', label: 'Redis' },
  { value: 'C++', label: 'C++' },
  { value: 'PostgreSQL', label: 'PostgreSQL' },
  { value: 'Restful API', label: 'Restful API' },
  { value: 'MongoDB', label: 'MongoDB' },
  { value: 'Django', label: 'Django' },
  { value: 'Jenkins', label: 'Jenkins' },
  { value: 'Go', label: 'Go' },
  { value: 'ElasticSearch', label: 'ElasticSearch' },
  { value: 'PHP', label: 'PHP' },
  { value: 'NoSQL', label: 'NoSQL' },
  { value: 'C', label: 'C' },
  { value: 'RDBMS', label: 'RDBMS' },
  { value: 'Oracle', label: 'Oracle' },
  { value: 'API', label: 'API' },
  { value: 'DevOps', label: 'DevOps' },
  { value: 'Golang', label: 'Golang' },
  { value: 'Nginx', label: 'Nginx' },
  { value: 'MSSQL', label: 'MSSQL' },
  { value: 'GraphQL', label: 'GraphQL' },
  { value: 'RabbitMQ', label: 'RabbitMQ' },
  { value: 'ORM', label: 'ORM' },
  { value: 'TDD', label: 'TDD' },
  { value: 'R', label: 'R' },
  { value: 'Hadoop', label: 'Hadoop' },
  { value: 'Laravel', label: 'Laravel' },
  { value: 'Angular', label: 'Angular' },
  { value: 'CSS', label: 'CSS' },
  { value: 'Docker', label: 'Docker' },
  { value: 'Figma', label: 'Figma' },
  { value: 'HTML', label: 'HTML' },
  { value: 'JavaScript', label: 'JavaScript' },
  { value: 'Laravel', label: 'Laravel' },
  { value: 'Next.js', label: 'Next.js' },
  { value: 'Node.js', label: 'Node.js' },
  { value: 'React', label: 'React' },
  { value: 'React.js', label: 'React.js' },
  { value: 'Svelte', label: 'Svelte' },
  { value: 'TypeScript', label: 'TypeScript' },
  { value: 'UX', label: 'UX' },
  { value: 'Vue.JS', label: 'Vue.JS' },
  { value: 'VueJS', label: 'VueJS' },
  { value: 'WebGL', label: 'WebGL' },
  { value: 'WebRTC', label: 'WebRTC' },
];
